import React, { useEffect, useState } from "react";
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from "@mui/material";
import { Box, FormControl, InputLabel, MenuItem, Select, TextField, Typography, Grid, InputAdornment } from "@mui/material";
import { SuppliersApi } from "@unity/components";
import ModuleButton from "../common/ModuleButton";
import ModuleFunctions from "./ModuleFunctions";
import IconButton from '@mui/material/IconButton';
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from '@mui/icons-material/Delete';

export default function DynamicLines(props) {
    const [suppliers, setSuppliers] = useState(false);

    const getSuppliers = async () => {
        const res = await SuppliersApi.getSuppliers();

        if(res.success) {
            setSuppliers(res.data);
        }
    };

    const handleAddOption = () => {
        const newData = props.options;
        newData.push({});

        ModuleFunctions.handleComponentChange({
            name: "options",
            value: newData,
            componentChange: props.componentChange
        })
    };

    const handleRemoveOption = (key) => {
        const newData = props.options;
        newData.splice(key, 1);

        ModuleFunctions.handleComponentChange({
            name: "options",
            value: newData,
            componentChange: props.componentChange
        })
    };

    const handleOptionChange = (properties) => {
        const newData = props.options;

        newData[properties.key]['material_id'] = properties.event.target.value;
        newData[properties.key]['material_name'] = properties.element.props.optionObj.brand
            ? (properties.element.props.optionObj.brand + ' ' + properties.element.props.optionObj.name)
            : properties.element.props.optionObj.name;
        newData[properties.key]['material_ref'] = properties.element.props.optionObj.reference;
        newData[properties.key]['supplier_id'] = properties.element.props.optionObj.supplier_id;
        newData[properties.key]['material_cost'] = properties.element.props.optionObj.unit_cost;
        newData[properties.key]['periodic_code_id'] = properties.element.props.optionObj.periodic_code_id;

        const supplier = suppliers.find(e => e.id === properties.element.props.optionObj.supplier_id);

        if(typeof supplier !== "undefined") {
            newData[properties.key]['supplier_name'] = supplier.name;
        };

        ModuleFunctions.handleComponentChange({
            name: "options",
            value: newData,
            componentChange: props.componentChange
        })
    };

    useEffect(() => {
        getSuppliers();
    }, []);


    return (
        <Box sx={{ border: 1, borderColor: "lightgray", borderRadius: 1 }}>
            <Grid container justifyContent="space-between">
                <Grid item>
                    <Typography sx={{ mt: 1, ml: 2 }} variant="subtitle1" component="div">
                        Dynamic Items
                    </Typography>
                </Grid>
                <Grid item>
                    {!props.uneditable && <ModuleButton
                        btnIcon="add"
                        style={{
                            marginTop: 10,
                            marginRight: 10,
                            background: "red",
                            color: "white"
                        }}
                        tip="Add Dynamic Item"
                        size="small"
                        onClick={() => handleAddOption()}
                    />}
                </Grid>
            </Grid>

            <TableContainer>
                <Table aria-label="dynamic items table" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" width="15%">Ref#</TableCell>
                            <TableCell align="left" width="50%">Item</TableCell>
                            <TableCell align="left" width="35%">Supplier</TableCell>
                            {/* <TableCell align="left" width="15%">Periodic Code</TableCell> */}
                            {/* <TableCell align="left" width="15%">Cost</TableCell> */}
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.options.map((item, key) => {
                            return (
                                <TableRow>
                                    
                                    <TableCell component="th" scope="row">
                                        <TextField
                                            label="Ref#"
                                            name="material_ref"
                                            type="text"
                                            value={item.material_ref || ""}
                                            fullWidth
                                            InputProps={{ readOnly: true }}
                                            size="small"
                                        ></TextField>
                                    </TableCell>
                                    <TableCell align="left">
                                        <FormControl fullWidth>
                                            <InputLabel>Item</InputLabel>
                                            <Select
                                                variant="standard"
                                                label="Item"
                                                name="material_id"
                                                value={item.material_id || ""}
                                                onChange={(evt, optionObj) => props.uneditable || handleOptionChange({
                                                    event: evt,
                                                    element: optionObj,
                                                    key: key
                                                })}
                                                inputProps={{ readOnly: props.uneditable }}
                                                size="small"
                                            >
                                                {props.materials && props.materials.map((item, key) => {
                                                    return <MenuItem key={key} value={item.id} optionObj={item}>{item.name}</MenuItem>
                                                })}
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                    <TableCell align="left">
                                        <TextField
                                            variant="standard"
                                            label="Supplier"
                                            name="supplier_name"
                                            type="text"
                                            value={item.supplier_name || ""}
                                            fullWidth
                                            InputProps={{ readOnly: true }}
                                            size="small"
                                        ></TextField>
                                    </TableCell>
                                    {/* <TableCell align="left">
                                        <FormControl fullWidth>
                                            <InputLabel>Periodic Code</InputLabel>
                                            <Select
                                                label="Periodic Code"
                                                name="periodic_code_id"
                                                value={item.periodic_code_id || ""}
                                                inputProps={{ readOnly: true }}
                                                size="small"
                                            >
                                                {props.periods && props.periods.map((item, key) => {
                                                    return <MenuItem key={key} value={item.id}>{item.name}</MenuItem>
                                                })}
                                            </Select>
                                        </FormControl>
                                    </TableCell> */}
                                    {/* <TableCell align="left">
                                        <TextField
                                            variant="standard"
                                            label="Cost"
                                            name="price"
                                            type="number"
                                            value={item.price || ""}
                                            fullWidth
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                                readOnly: true
                                            }}
                                            size="small"
                                        ></TextField>
                                    </TableCell> */}
                                    <TableCell align="right">
                                        {!props.uneditable && <Tooltip title="Delete dynamic option">
                                            <IconButton
                                                edge="end"
                                                aria-label="delete"
                                                onClick={() => handleRemoveOption(key)}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>}
                                    </TableCell>

                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}
