import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { ProductsApi, LoadingComponent, ApiLoaderComponent } from "@unity/components";
import { FormControl, FormHelperText, TextField, InputLabel, Select, MenuItem } from "@mui/material";
import PropTypes from 'prop-types';
import clsx from 'clsx';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem, { useTreeItem } from '@mui/lab/TreeItem';
import Typography from '@mui/material/Typography';
import ModuleButton from "../common/ModuleButton";
import ModuleFunctions from "./ModuleFunctions";
import RelatedResources from "./RelatedResources";
import ScheduleCapture from "./ScheduleCapture";
import ProductDocuments from "./ProductDocuments";
import UploadedDocumentEdit from "./UploadedDocumentEdit";

const CustomContent = React.forwardRef(function CustomContent(props, ref) {
    const {
        classes,
        className,
        label,
        nodeId,
        icon: iconProp,
        expansionIcon,
        displayIcon,
        model,
        setOpenModel,
        getModel
    } = props;
  
    const {
        disabled,
        expanded,
        selected,
        focused,
        handleExpansion,
        handleSelection,
        preventSelection,
    } = useTreeItem(nodeId);
  
    const icon = iconProp || expansionIcon || displayIcon;
  
    const handleMouseDown = (event) => {
        preventSelection(event);
    };
  
    const handleExpansionClick = (event) => {
        handleExpansion(event);
    };
  
    const handleSelectionClick = (model) => {
        handleSelection();
        setOpenModel(model);
        getModel(model);
    };
  
    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
            className={clsx(className, classes.root, {
                [classes.expanded]: expanded,
                [classes.selected]: selected,
                [classes.focused]: focused,
                [classes.disabled]: disabled,
            })}
            onMouseDown={handleMouseDown}
            ref={ref}
        >
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div onClick={handleExpansionClick} className={classes.iconContainer}>
                {icon}
            </div>
            <Typography
                onClick={() => handleSelectionClick(model)}
                component="div"
                className={classes.label}
            >
                {label}
            </Typography>
        </div>
    );
});
  
CustomContent.propTypes = {
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object.isRequired,
    /**
     * className applied to the root element.
     */
    className: PropTypes.string,
    /**
     * The icon to display next to the tree node's label. Either a parent or end icon.
     */
    displayIcon: PropTypes.node,
    /**
     * The icon to display next to the tree node's label. Either an expansion or collapse icon.
     */
    expansionIcon: PropTypes.node,
    /**
     * The icon to display next to the tree node's label.
     */
    icon: PropTypes.node,
    /**
     * The tree node label.
     */
    label: PropTypes.node,
    /**
     * The id of the node.
     */
    nodeId: PropTypes.string.isRequired,
};
  
const CustomTreeItem = (props) => {
    return (
        <TreeItem
            {...props}
            ContentComponent={CustomContent}
            ContentProps={{
                model: props.model,
                setOpenModel: props.setOpenModel,
                getModel: props.getModel
            }}
        />
    );
};


export default function ProductStructure({ context, create, read, edit, admin, readAll }) {
    const [structure, setStructure] = useState(false);
    const [channels, setChannels] = useState(false);
    const [schedules, setSchedules] = useState(false);
    const [documents, setDocuments] = useState(false);
    const [tags, setTags] = useState(false);
    const [openModel, setOpenModel] = useState(false);
    const [errors, setErrors] = useState(false);
    const [open, setOpen] = useState(false);
    const [media, setMedia] = useState(false);
    const [docEdit, setDocEdit] = useState(false);
    const [dialogData, setDialogData] = useState(false);
    const [loading, setLoading] = useState({ status: false });
    const [fetchTrigger, setFetchTrigger] = useState(0);// This is used to determine if page needs to be re-rendered. The value doesn't matter, just the change!

    let history = useHistory();
    let counter = 96;// 97 to 121 are ASCII a to z --> character to add to the id in the tree to give some difference as the id's may be the same and this would cause the render to fail!

    const dialogChange = (data) => {
        setOpen(true);
        setDialogData(prevState => ({
            ...prevState,
            ...data
        }));
    };

    const mediaChange = (data) => {
        setMedia(data);
    };

    const documentChange = (data) => {
        setDocEdit(true);
        setDialogData(prevState => ({
            ...prevState,
            ...data
        }));
    };

    const triggerChange = () => {
        setFetchTrigger(fetchTrigger + 1);
    };

    const channelChange = (data) => {
        setChannels(data);
    };

    const structChange = (data) => {
        setStructure(data);
    };

    const scheduleChange = (data) => {
        setSchedules(data);
    };

    const docsChange = (data) => {
        setDocuments(data);
    };

    const tagsChange = (data) => {
        setTags(data);
    };

    const modelChange = (data) => {
        setOpenModel(prevState => ({
            ...prevState,
            ...data
        }));
    };

    const getModel = async (model) => {
        if (model.id && model.model_name) {
            const res = await ProductsApi[`get${model.model_name}`](model.id);
            if(res.success) {
                modelChange(res.data);
            }
        }
    };

    const handleSaveModel = async () => {
        setLoading({ status: true, data: "Saving your record, Please Wait...." });

        let res = null;

        if(openModel.id) {
            res = await ProductsApi[`update${openModel.model_name}`](openModel.id, openModel);
        } else {
            res = await ProductsApi[`save${openModel.model_name}`](openModel);
        }

        if(res.success) {
            triggerChange();
            setOpenModel(false);
            setLoading({ status: false });
        } else {
            if(res.errors) {
                setErrors(res.errors);
                setLoading({ status: true, data: "Validation Errors!, Please Wait...." });
                setTimeout(() => {
                    setLoading({ status: false });
                }, 3000);
            } else {
                setLoading({ status: true, data: res.message });
                setTimeout(() => {
                    history.push("/prodsucts/index");
                    setLoading({ status: false });
                }, 3000);
            }
        }
    };

    const handleDeleteModel = async () => {
        setLoading({ status: true, data: "Deleting record, Please Wait...." });

        const res = await ProductsApi[`delete${openModel.model_name}`](openModel.id);

        if(res.success) {
            triggerChange();
            setOpenModel(false);
            setErrors(false);
            setLoading({ status: false });
        } else {
            setLoading({ status: true, data: res.message });
            setTimeout(() => {
                history.push("/prodsucts/index");
                setLoading({ status: false });
            }, 3000);
        }
    };
    
    const handleCaptureSubmit = async () => {
        setLoading({ status: true, data: "Saving your reference, Please Wait...." });
    
        const res = await ProductsApi.saveSchedule(dialogData);
    
        if(res.success) {
            setTimeout(() => {
                triggerChange();
                setOpen(false);
                setDialogData(false);
                setErrors(false);
                setLoading({ status: false });
            });
        } else {
            setLoading({ status: true, data: res.message });
            setTimeout(() => {
                history.push('/products/index');
                setLoading({ status: false });
            }, 3000);
        }
    };
    
    const handleDocumentSubmit = async () => {
        setLoading({ status: true, data: "Saving your document changes, Please Wait...." });
    
        const res = await ProductsApi.updateDocument(dialogData.id, dialogData);
    
        if(res.success) {
            setTimeout(() => {
                setDocEdit(false);
                setDialogData(false);
                setErrors(false);
                setLoading({ status: false });
                // triggerChange();

            });

            getModel(openModel);
        } else {
            setLoading({ status: true, data: res.message });
            setTimeout(() => {
                history.push('/products/index');
                setLoading({ status: false });
            }, 3000);
        }
    };

    //---------------------------------------------------------------------------------------------------------------------------------------
    // All the functions in here relate to rendering the Tree
    
    const getChildName = () => {
        const words = openModel.children_array.split('_');
        const word = words[1].slice(0, -1);
        return word[0].toUpperCase() + word.substring(1);
    };

    const handleAddChildModel = () => {
        const newOpen = {
            sbu_id: openModel.type_id,
            prod_cat_id: openModel.prod_cat_id ? openModel.prod_cat_id : openModel.id,
            group_id: openModel.prod_cat_id ? openModel.id : null,
            model_name: getChildName(),// to display model name
            changed: true// so the save button is displayed
        };
        setOpenModel(newOpen);
    };

    // used to render the first layer if it is an array
    const renderTree = (struct) => (
        Array.isArray(struct)
            ? struct.map((bu) => renderChildren(bu))
            : null
    );

    // used to render an individual node and it's children recursively
    // NB: it is important that the nodes have a field on named 'children_tag' to tell the function what the name of the next layers array is.
    //     it is also important that all of the 'keys' & 'nodeId' are unique.
    const renderChildren = (parent) => {
        // update the counter
        if (counter === 122) {
            counter = 97;
        } else {
            counter++;
        }

        return (
            <CustomTreeItem
                key={String.fromCharCode(counter)+'_'+parent.id+'_'+parent.children_array}
                nodeId={String.fromCharCode(counter)+'_'+parent.id+'_'+parent.children_array}
                label={parent.name}
                model={parent}// added the model to pass through when selected
                setOpenModel={setOpenModel}
                getModel={getModel}
            >
                {Array.isArray(parent[parent.children_array])
                    ? parent[parent.children_array].map((node) => renderChildren(node))
                    : null}
            </CustomTreeItem>
        );
    };
    //-----------------------------------------------------------------------------------------------------------------------------------------
    

    useEffect(() => {
        ModuleFunctions.getStructure({ structChange: structChange });
        ModuleFunctions.getChannels({ channelChange: channelChange });
        ModuleFunctions.getSchedules({ scheduleChange: scheduleChange });// this is the list of available schedules for linking
        ModuleFunctions.getDocuments({ docsChange: docsChange });// this is the list of available documents for linking
        ModuleFunctions.getTags({ tagsChange: tagsChange });
        if(openModel) {
            getModel(openModel);
        }
    }, [fetchTrigger]);



    if (structure) {
        return (
            <>
                <div className="block">
                    <div className="container-fluid">
                        <div style={{ width: '100%' }}>
                            <div className="form-row">
                                <div className="form-group col-lg-12">
                                    <h4>Product Structure</h4>
                                </div>
                            </div>

                            <TreeView
                                aria-label="rich object"
                                defaultCollapseIcon={<ExpandMoreIcon />}
                                defaultExpandIcon={<ChevronRightIcon />}
                                sx={{ height: 'auto', flexGrow: 1, maxWidth: '100%', overflowY: 'auto' }}
                            >
                                {renderTree(structure)}
                            </TreeView>
                        </div>
                    </div>
                </div>

                {openModel && (
                    <div className="block">
                        <div className="container-fluid">
                            <div style={{ width: '100%' }}>
                                <div className="form-row">
                                    <div className="form-group col-lg-8">
                                        <h4>{openModel.model_name ? ('Product '+openModel.model_name) : openModel.fixed_name}</h4>
                                    </div>

                                    <div className="form-group col-lg-4">
                                        {admin && !openModel.fixed_children ? (
                                            openModel.changed ? (
                                                <>
                                                    <ModuleButton
                                                        btnIcon="check"
                                                        text="save"
                                                        style={{
                                                            background: "green",
                                                            color: "white",
                                                            marginRight: 10
                                                        }}
                                                        onClick={() => handleSaveModel()}
                                                    />
                                                    <ModuleButton
                                                        btnIcon="close"
                                                        text="cancel"
                                                        style={{
                                                            background: "red",
                                                            color: "white"
                                                        }}
                                                        onClick={() => setOpenModel(false)}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    {openModel.children_array ? (
                                                        <ModuleButton
                                                            btnIcon="add"
                                                            text={'Add Product ' + getChildName()}
                                                            style={{
                                                                background: context.theme.sidebar.background,
                                                                color: "white",
                                                                marginRight: 10
                                                            }}
                                                            onClick={() => handleAddChildModel()}
                                                        />
                                                    ) : (
                                                        <></>
                                                    )}
                                                    {openModel.model_name &&
                                                        (!openModel.children_array || openModel[openModel.children_array].length === 0) &&
                                                        <ModuleButton
                                                            btnIcon="delete"
                                                            text="delete"
                                                            style={{
                                                                background: "red",
                                                                color: "white"
                                                            }}
                                                            onClick={() => handleDeleteModel()}
                                                        />
                                                    }
                                                </>
                                            )
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                </div>

                                <div className="form-row">
                                  <div className="form-group-sm col-lg-8">
                                    <TextField
                                      label="Name"
                                      name="name"
                                      type="text"
                                      value={openModel.name || ""}
                                      error={errors && errors.name || false}
                                      helperText={errors && errors.name || ""}
                                      onChange={(e) => ModuleFunctions.handleModelChange({ name: e.target.name, value: e.target.value, modelChange: modelChange })}
                                      size="small"
                                      fullWidth={true}
                                      InputProps={{
                                        readOnly: (!admin || !openModel.model_name)
                                      }}
                                    ></TextField>
                                  </div>

                                  <div className="form-group-sm col-lg-4">
                                        <FormControl error={errors && errors.channel_id || false} fullWidth>
                                            <InputLabel>Sales Channel</InputLabel>
                                            <Select
                                                label="Sales Channel"
                                                name="channel_id"
                                                value={openModel.channel_id || ""}
                                                onChange={(e) => !admin || !openModel.model_name || ModuleFunctions.handleModelChange({ name: e.target.name, value: e.target.value, modelChange: modelChange })}
                                                inputProps={{
                                                    readOnly: (!admin || !openModel.model_name),
                                                }}
                                                size="small"
                                            >
                                                {channels && channels.map((item, key) => {
                                                    return <MenuItem key={key} value={item.id}>{item.name}</MenuItem>
                                                })}
                                            </Select>
                                            <FormHelperText>{errors && errors.channel_id || ""}</FormHelperText>
                                        </FormControl>
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="form-group-sm col-lg-12">
                                        <TextField
                                            label="Description"
                                            name="description"
                                            value={openModel.description || ""}
                                            multiline
                                            minRows={1}
                                            maxRows={3}
                                            error={errors && errors.description || false}
                                            helperText={errors && errors.description || ""}
                                            onChange={(e) => ModuleFunctions.handleModelChange({ name: e.target.name, value: e.target.value, modelChange: modelChange })}
                                            size="small"
                                            fullWidth={true}
                                            InputProps={{
                                                readOnly: (!admin || !openModel.model_name)
                                            }}
                                        ></TextField>
                                    </div>
                                </div>

                                {openModel.id && openModel.model_name && (
                                    <>
                                        <RelatedResources
                                            admin={admin}
                                            context={context}
                                            object_id={openModel.id}
                                            object_type={openModel.model_name[0].toLowerCase() + openModel.model_name.substring(1)}
                                            references={openModel.schedules}
                                            linkedDocs={openModel.documents}
                                            tags={tags}
                                            dialogChange={dialogChange}
                                            mediaChange={mediaChange}
                                            triggerChange={triggerChange}
                                        />

                                        <ProductDocuments
                                            admin={admin}
                                            context={context}
                                            object_id={openModel.id}
                                            object_type={openModel.model_name[0].toLowerCase() + openModel.model_name.substring(1)}
                                            linkedDocs={openModel.documents}
                                            open={media}
                                            data={documents}
                                            documentChange={documentChange}
                                            onClose={mediaChange}
                                            triggerChange={triggerChange}
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                )}

                <ScheduleCapture
                    title="Document Reference Selection"
                    info="You can select a published document, and optionally a specific section of that document to reference."
                    open={open}
                    setOpen={setOpen}
                    errors={errors}
                    setErrors={setErrors}
                    dialogData={dialogData}
                    setDialog={setDialogData}
                    schedules={schedules}
                    dialogChange={dialogChange}
                    handleSubmit={handleCaptureSubmit}
                />

                <UploadedDocumentEdit
                    title="Uploaded Document Edit"
                    info={`Edit attributes for document: ${dialogData.name}`}
                    open={docEdit}
                    setOpen={setDocEdit}
                    errors={errors}
                    setErrors={setErrors}
                    dialogData={dialogData}
                    setDialog={setDialogData}
                    tags={tags}
                    documentChange={documentChange}
                    handleSubmit={handleDocumentSubmit}
                />

                <ApiLoaderComponent
                    status={loading.status}
                    data={loading.data}
                />
            </>
        );
    } else {
        return <LoadingComponent color={context.theme.sidebar.background} />;
    }
}
