import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import DataTable from "../common/DataTable";
import { ProductsApi, ApiLoaderComponent, LoadingComponent } from "@unity/components";
import { CanDelete } from "../services/Permissions";
import ModuleButton from "../common/ModuleButton";
import InputCapture from "../common/InputCapture";
import ModuleFunctions from "./ModuleFunctions";

export default function ReviewIndex({ context, create, read, edit, admin, readAll, bus }) {
  const [loading, setLoading] = useState({ status: false });
  const [data, setData] = useState(false);
  const [errors, setErrors] = useState(false);
  const [open, setOpen] = useState(false);
  const [dialogData, setDialogData] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pagination, setPagination] = useState(true);// Flag to switch between unpaginated and paginated data!
  const [rowCount, setRowCount] = useState(0);// The total number of rows available from the search!
  const [searchText, setSearchText] = useState('');
  const [fetchTrigger, setFetchTrigger] = useState(0);// This is used to determine if getDocs() needs to be called. The value doesn't matter, just the change!

  const history = useHistory();

  const dialogChange = (data) => {
    setOpen(true);
    setDialogData(prevState => ({
      ...prevState,
      ...data
    }));
  };

  const getProds = async () => {
    const res = await ProductsApi.getReviewProducts({ read_all: readAll, pagination: pagination, page: page + 1, per_page: pageSize, search_term: searchText });

    if (res.success) {
      setData(res.data);
      if (pagination) {
        setRowCount(res.meta.total);
      }
    } else {
      // the request has failed, load an empty array!
      setData([]);
    }
  };

  const handlePageChange = (page) => {
    setPage(page);

    if ( pagination ) {
      setFetchTrigger(fetchTrigger + 1);
    }
  };

  const handleSizeChange = (size) => {
    setPageSize(size);

    if( pagination ) {
      setFetchTrigger(fetchTrigger + 1);
    }
  };

  const handleLinkClick = (event, params, path) => {
      event.preventDefault();
      history.push({
        pathname: `/products/${path}/${params.id}`,
        state: { ...params }
      });
  };

  // The following functions are both required for the correct
  // async use of deleting multiple rows!
  // -----------------------------------------------------------------------------------
  const handleDelete = async (id) => {
    await ProductsApi.deleteProduct(id);
  };

  const handleDeleteArray = async (array) => {
    setLoading({ status: true, data: "Deleting products, please wait..." });
    const unresolvedPromises = array.map(id => handleDelete(id));

    await Promise.all(unresolvedPromises).then(success => {
      setLoading({ status: true, data: "Succssfully deleted products!" });
    }).catch(error => {
      setLoading({ status: true, data: `Unable to delete product with id: ${error}` });
    });
    
    setFetchTrigger(fetchTrigger + 1);
    
    setTimeout(() => {
      setLoading({ status: false });
    }, 3000);
  };
  // -----------------------------------------------------------------------------------

  const handleApproval = async (id) => {
    setLoading({ status: true, data: 'Approving product, please wait...' });
    const res = await ProductsApi.productApproval(id, {});
    if(res.success) {
      setLoading({ status: false });
    } else {
      setLoading({ status: true, data: res.message });
      setTimeout(() => {
        setLoading({ status: false });
      }, 3000);
    }

    setFetchTrigger(fetchTrigger + 1);
  };

  const handleRejectRequest = async () => {
    setLoading({ status: true, data: 'Cancelling approval request for product, please wait...' });

    const testResults = ModuleFunctions.testReason({ reason: dialogData.reason });

    if(testResults) {
      setErrors(testResults);
      setLoading({ status: false });
    } else {
      const res = await ProductsApi.productRejection(dialogData.id, { reason: dialogData.reason });

      if (res.errors) {
        setLoading({ status: true, data: 'Unable to cancel approval request for product!' });
        setTimeout(setLoading({ status: false }), 3000);
      } else {
        setLoading({ status: false });
        setOpen(false);
        setDialogData(false);
        setErrors(false);
      }
  
      setFetchTrigger(fetchTrigger + 1);
    }
  };

  const columns = [
    { field: "ref", headerName: "Ref#", flex: 0.3 },
    { field: "version", headerName: "ver", flex: 0.1 },
    { field: "name", headerName: "Product Name", flex: 1 },
    {
      field: "sbu_id",
      headerName: "Business Unit",
      minWidth: 200,
      renderCell: (cellValues) => {
          if(cellValues.row.sbu_id) {
              const pc = bus.find(e => cellValues.row.sbu_id === e.id)
              return pc.name;
          } else {
              return null;
          }
      }
    },
    { field: "owner_name", headerName: "Product Owner", flex: 0.5 },
    {
      field: "",
      headerName: "Action",
      minWidth: 240,
      renderCell: (cellValues) => {
        return (
          <div>
            <ModuleButton
              btnIcon="visibility"
              style={{
                background: context.theme.sidebar.background,
                color: "white"
              }}
              onClick={(event) => handleLinkClick(event, cellValues.row, "view")}
              tip="View"
            />
            {admin &&
                <ModuleButton
                btnIcon="card_membership"
                style={{
                  background: "green",
                  color: "white",
                  marginLeft: 10
                }}
                tip="Approve"
                onClick={() => handleApproval(cellValues.row.id)}
              />
            }
            {admin &&
              <ModuleButton
                btnIcon="backspace"
                style={{
                  background: "red",
                  color: "white",
                  marginLeft: 10
                }}
                tip="Reject Request"
                onClick={() => dialogChange({ id: cellValues.row.id })}
              />
            }
            
          </div>
        );
      },
    }
  ];

  const options = {
    page: page,
    handlePageChange: handlePageChange,
    pageSize: pageSize,
    handleSizeChange: handleSizeChange,
    rowsPerPageOptions: [10, 25, 100],
    rowCount: rowCount,
    autoHeight: true,
    height: 680
  };
  
  
  useEffect(() => {
    getProds();
  }, [fetchTrigger]);



  if (read && data) {
    return (
      <>
        <DataTable
          columns={columns}
          data={data}
          pagination={pagination}
          options={options}
          allowDelete={CanDelete("products")}
          handleDeleteArray={handleDeleteArray}
          searchText={searchText}
          setSearchText={setSearchText}
          fetchTrigger={fetchTrigger}
          setFetchTrigger={setFetchTrigger}
        />

        <InputCapture
          title="Rejection Reason"
          info="You must supply a reason to inform the requester why the approval request has been rejected"
          open={open}
          setOpen={setOpen}
          errors={errors}
          setErrors={setErrors}
          dialogData={dialogData}
          setDialog={setDialogData}
          dialogChange={dialogChange}
          handleSubmit={handleRejectRequest}
        />

        <ApiLoaderComponent status={loading.status} data={loading.data} />
      </>
    );
  } else {
    return <LoadingComponent color={context.theme.sidebar.background} />;
  }
}
