import React, { useState, useEffect } from "react";
import DataTable from "../common/DataTable";
import { ProductsApi, ApiLoaderComponent, LoadingComponent } from "@unity/components";
import { CanDelete } from "../services/Permissions";
import ModuleButton from "../common/ModuleButton";

export default function ProductMaterials(props) {
  const [loading, setLoading] = useState({ status: false });
  const [components, setComponents] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pagination, setPagination] = useState(false);// Flag to switch between unpaginated and paginated data!
  const [rowCount, setRowCount] = useState(0);// The total number of rows available from the search!
  const [searchText, setSearchText] = useState('');
  const [fetchTrigger, setFetchTrigger] = useState(0);// This is used to determine if getDocs() needs to be called. The value doesn't matter, just the change!

  const handlePageChange = (page) => {
    setPage(page);

    if ( pagination ) {
      setFetchTrigger(fetchTrigger + 1);
    }
  };

  const handleSizeChange = (size) => {
    setPageSize(size);

    if( pagination ) {
      setFetchTrigger(fetchTrigger + 1);
    }
  };

  // The following functions are both required for the correct
  // async use of deleting multiple rows!
  // -----------------------------------------------------------------------------------
  const handleDelete = async (id) => {
    await ProductsApi.deleteComponent(id);
  };

  const handleDeleteArray = async (array) => {
    setLoading({ status: true, data: "Deleting product items, please wait..." });
    const unresolvedPromises = array.map(id => handleDelete(id));

    await Promise.all(unresolvedPromises).then(success => {
      setLoading({ status: true, data: "Succssfully deleted product items!" });
    }).catch(error => {
      setLoading({ status: true, data: `Unable to delete product item with id: ${error}` });
    });
    
    // props.setCalcTrigger(true);
    
    setTimeout(() => {
      setLoading({ status: false });
    }, 3000);
  };
  // -----------------------------------------------------------------------------------

  const columns = [
    {
        field: "dynamic",
        headerName: "Type",
        minWidth: 100,
        renderCell: (cellValues) => {
            return ( cellValues.row.dynamic ? 'Dynamic' : 'Static' );
        }
    },
    { field: "material_name", headerName: "Item", flex: 0.5 },
    {
        field: "periodic_code_id",
        headerName: "Periodic Code",
        minWidth: 200,
        renderCell: (cellValues) => {
            if(cellValues.row.periodic_code_id) {
                const pc = props.periods.find(e => cellValues.row.periodic_code_id === e.id)
                return pc.name;
            } else {
                return null;
            }
        }
    },
    { field: "quantity", headerName: "Quantity", flex: 0.2 },
    { field: "price", headerName: "Price", flex: 0.2 },
    {
      field: "action",
      headerName: "Action",
      minWidth: 100,
      renderCell: (cellValues) => {
        return (
          <div>
            {props.type === "edit" && !props.uneditable ? (
              <ModuleButton
                btnIcon="edit"
                style={{
                  background: "orange",
                  color: "white",
                  marginLeft: 10
                }}
                onClick={() => {
                  props.setMatEdit(true);
                  props.setDialogData({
                    ...cellValues.row,
                    asset: props.asset
                  });
                }}
                tip="Edit Item"
              />
            ) : (
              <ModuleButton
                btnIcon="visibility"
                style={{
                  background: props.context.theme.sidebar.background,
                  color: "white"
                }}
                onClick={() => {
                  props.setMatEdit(true);
                  props.setDialogData(cellValues.row);
                }}
                tip="View Item"
              />
            )}
          </div>
        );
      },
    }
  ];

  const options = {
    page: page,
    handlePageChange: handlePageChange,
    pageSize: pageSize,
    handleSizeChange: handleSizeChange,
    rowsPerPageOptions: [10, 25, 100],
    rowCount: rowCount,
    autoHeight: true,
    height: 680
  };
  
  useEffect(() => {
    setComponents(props.data.components.filter(element => element.material_cat_id === props.category));
  }, [props.category, props.data.components]);


  if (props.read && components) {
    return (
      <>
        <DataTable
            columns={columns}
            data={components}
            pagination={pagination}
            options={options}
            allowDelete={!props.uneditable && CanDelete("products")}
            handleDeleteArray={handleDeleteArray}
            searchText={searchText}
            setSearchText={setSearchText}
            fetchTrigger={fetchTrigger}
            setFetchTrigger={setFetchTrigger}
        />

        <ApiLoaderComponent status={loading.status} data={loading.data} />
      </>
    );
  } else {
    return <LoadingComponent color={props.context.theme.sidebar.background} />;
  }
}
