import React, { useState, useEffect } from "react";
import { AppHeaderComponent, LoadingComponent } from "@unity/components";
import { CanCreate, CanRead, CanUpdate, CanModuleAdmin } from "../services/Permissions";
import ModuleTabs from "../common/ModuleTabs";
import ProductOverview from "../modules/ProductOverview";
import BillOfProduct from "../modules/BillOfProduct";
import ProductMaterials from "../modules/ProductMaterials";
import ProductLabour from "../modules/ProductLabour";
import ProductActions from "../modules/ProductActions";
import ModuleFunctions from "../modules/ModuleFunctions";
import RelatedResources from "../modules/RelatedResources";
import MaterialSingle from "../modules/MaterialSingle";
import LabourSingle from "../modules/LabourSingle";

export default function ProductView(props) {
  const [tabs, setTabs] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [dynamicTabs, setDynamicTabs] = useState(false);
  const [fetchTrigger, setFetchTrigger] = useState(0);// This is used to determine if page needs to be re-rendered. The value doesn't matter, just the change!
  const [data, setData] = useState(false);
  const [errors, setErrors] = useState(false);
  const [personnel, setPersonnel] = useState(false);
  const [channels, setChannels] = useState(false);
  const [structure, setStructure] = useState(false);
  const [periods, setPeriods] = useState(false);
  const [charges, setCharges] = useState(false);
  const [methods, setMethods] = useState(false);
  const [vats, setVats] = useState(false);
  const [tags, setTags] = useState(false);
  const [matEdit, setMatEdit] = useState(false);
  const [labEdit, setLabEdit] = useState(false);
  const [dialogData, setDialogData] = useState(false);

  const create = CanCreate("products");
  const read = CanRead("products");
  const edit = CanUpdate("products");
  const admin = CanModuleAdmin("products");

  // TODO: at some point will need to add the functionality to handle situations where old products have categories that have been deleted!
  const tabsChange = (tabData) => {
    const newData = [{ 
      icon: "dvr",
      label: "overview",
      component: "ProductOverview"
    },{ 
      icon: "summarize",
      label: "BOP",
      component: "BillOfProduct"
    }];

    Array.isArray(tabData)
      ? tabData.map((cat) => {
          newData.push({
            icon: cat.icon_name,
            label: cat.name,
            category: cat.id,// for getting the related materials on the page
            component: cat.capital_asset ? "ProductAssets" : "ProductMaterials"// to determine the rendered component
          });
        })
      : null;

    newData.push({
      icon: "directions_walk",
      label: "labour",
      component: "ProductLabour"
    },{
      icon: "event",
      label: "actions",
      component: "ProductActions"
    });

    setTabs(newData);
    setDynamicTabs(tabData);
  };

  const dialogChange = (data) => {
    setOpen(true);
    setDialogData(prevState => ({
        ...prevState,
        ...data
    }));
  };

  const mediaChange = (data) => {
    setMedia(data);
  };

  const componentChange = (data) => {
      setMatEdit(true);
      setDialogData(prevState => ({
          ...prevState,
          ...data
      }));
  };

  const labourChange = (data) => {
      setLabEdit(true);
      setDialogData(prevState => ({
          ...prevState,
          ...data
      }));
  };

  const productChange = (data) => {
      setData(prevState => ({
          ...prevState,
          ...data
      }));
  };

  const triggerChange = () => {
      setFetchTrigger(fetchTrigger + 1);
  };

  const handleTabChange = (event, value) => {
    setSelectedTab(value);
  };

  const personnelChange = (data) => {
    setPersonnel(data);
  };

  const structChange = (data) => {
      setStructure(data);
  };

  const channelChange = (data) => {
      setChannels(data);
  };

  const periodsChange = (data) => {
      setPeriods(data);
  };

  const vatsChange = (data) => {
      setVats(data);
  };

  const chargesChange = (data) => {
      setCharges(data);
  };

  const methodsChange = (data) => {
      setMethods(data);
  };

  const tagsChange = (data) => {
      setTags(data);
  };

  const handleComponentSave = async () => {};


  useEffect(() => {
    ModuleFunctions.getMaterialCats({ tabsChange: tabsChange });
    ModuleFunctions.getProduct({ id: props.route.match.params.id, productChange: productChange });
    ModuleFunctions.getPersonnel({ personnelChange: personnelChange });
    ModuleFunctions.getStructure({ structChange: structChange });
    ModuleFunctions.getChannels({ channelChange: channelChange });
    ModuleFunctions.getPeriodicCodes({ periodsChange: periodsChange });
    ModuleFunctions.getChargeCodes({ chargesChange: chargesChange });
    ModuleFunctions.getPaymentMethods({ methodsChange: methodsChange });
    ModuleFunctions.getVatRates({ vatsChange: vatsChange });
    ModuleFunctions.getTags({ tagsChange: tagsChange });
  }, [fetchTrigger]);


  
  if(tabs && read && data && structure) {
    return (  
      <>
        <AppHeaderComponent
          context={props.context}
          theme={props.context.theme}
          name="products"
          subpage="view"
          spacer={true}
        />

        <ModuleTabs tabs={tabs} value={selectedTab} setValue={setSelectedTab} handleChange={handleTabChange} />
        {tabs[selectedTab].component === "ProductOverview" &&
          <div style={{ paddingTop: 66 }}>
            <ProductOverview
              context={props.context}
              type='view'
              create={create}
              read={read}
              edit={edit}
              data={data}
              errors={errors}
              personnel={personnel}
              channels={channels}
              structure={structure}
              periods={periods}
              charges={charges}
              methods={methods}
              vats={vats}
              productChange={productChange}
            />

           <RelatedResources
              admin={admin}
              uneditable={read || data.retired}
              context={props.context}
              object_id={props.route.match.params.id}
              object_type="product"
              references={data.schedules}
              linkedDocs={data.documents}
              tags={tags}
              dialogChange={dialogChange}
              mediaChange={mediaChange}
              triggerChange={triggerChange}
            />
          </div>
        }
        {tabs[selectedTab].component === "BillOfProduct" &&
          <div style={{ paddingTop: 66 }}>
            <BillOfProduct
              uneditable={read || data.retired}
              data={data}
              periods={periods}
              productChange={productChange}
            />
          </div>
        }
        {((tabs[selectedTab].component === "ProductAssets") ||
          (tabs[selectedTab].component === "ProductMaterials")) &&
            <div style={{ paddingTop: 86 }}>
              <ProductMaterials
                context={props.context}
                type='view'
                category={tabs[selectedTab].category}
                uneditable={read || data.retired}
                read={read}
                data={data}
                periods={periods}
                setMatEdit={setMatEdit}
                setDialogData={setDialogData}
                triggerChange={triggerChange}
              />
            </div>
        }
        {tabs[selectedTab].component === "ProductLabour" &&
          <div style={{ paddingTop: 86 }}>
            <ProductLabour
              context={props.context}
              type='view'
              uneditable={read || data.retired}
              read={read}
              data={data}
              periods={periods}
              setLabEdit={setLabEdit}
              setDialogData={setDialogData}
              triggerChange={triggerChange}
            />
          </div>
        }
        {tabs[selectedTab].component === "ProductActions" &&
          <div style={{ paddingTop: 86 }}>
            <ProductActions
              context={props.context}
              type='view'
              create={create}
              read={read}
              edit={edit}
              data={data}
              errors={errors}
              personnel={personnel}
              channels={channels}
              structure={structure}
              productChange={productChange}
            />
          </div>
        }

        <MaterialSingle
          context={props.context}
          asset={tabs[selectedTab].component === "ProductAssets"}
          open={matEdit}
          setOpen={setMatEdit}
          errors={errors}
          setErrors={setErrors}
          dialogData={dialogData}
          setDialog={setDialogData}
          data={data}
          periods={periods}
          vats={vats}
          category={tabs[selectedTab].category}
          title={tabs[selectedTab].label}
          uneditable={read || data.retired}
          componentChange={componentChange}
          handleSubmit={handleComponentSave}
        />

        <LabourSingle
          context={props.context}
          open={labEdit}
          setOpen={setLabEdit}
          errors={errors}
          setErrors={setErrors}
          dialogData={dialogData}
          setDialog={setDialogData}
          data={data}
          periods={periods}
          vats={vats}
          category={tabs[selectedTab].category}
          title={tabs[selectedTab].label}
          uneditable={read || data.retired}
          labourChange={labourChange}
          handleSubmit={handleComponentSave}
        />
      </>
    );
  } else {
    return <LoadingComponent color={props.context.theme.sidebar.background} />;
  }
}
