import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import DataTable from "../common/DataTable";
import { ProductsApi, ApiLoaderComponent, LoadingComponent } from "@unity/components";
import { CanDelete } from "../services/Permissions";
import ModuleButton from "../common/ModuleButton";

export default function WorkingIndex({ context, create, read, edit, admin, readAll, bus }) {
  const [loading, setLoading] = useState({ status: false });
  const [data, setData] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pagination, setPagination] = useState(true);// Flag to switch between unpaginated and paginated data!
  const [rowCount, setRowCount] = useState(0);// The total number of rows available from the search!
  const [searchText, setSearchText] = useState('');
  const [fetchTrigger, setFetchTrigger] = useState(0);// This is used to determine if getDocs() needs to be called. The value doesn't matter, just the change!

  const history = useHistory();

  const getProds = async () => {
    const res = await ProductsApi.getWorkingProducts({ read_all: readAll, pagination: pagination, page: page + 1, per_page: pageSize, search_term: searchText });

    if (res.success) {
      setData(res.data);
      if (pagination) {
        setRowCount(res.meta.total);
      }
    } else {
      // the request has failed, load an empty array!
      setData([]);
    }
  };

  const handlePageChange = (page) => {
    setPage(page);

    if ( pagination ) {
      setFetchTrigger(fetchTrigger + 1);
    }
  };

  const handleSizeChange = (size) => {
    setPageSize(size);

    if( pagination ) {
      setFetchTrigger(fetchTrigger + 1);
    }
  };

  const handleLinkClick = (event, params, path) => {
      event.preventDefault();
      history.push({
        pathname: `/products/${path}/${params.id}`,
        state: { ...params }
      });
  };

  // The following functions are both required for the correct
  // async use of deleting multiple rows!
  // -----------------------------------------------------------------------------------
  const handleDelete = async (id) => {
    await ProductsApi.deleteProduct(id);
  };

  const handleDeleteArray = async (array) => {
    setLoading({ status: true, data: "Deleting products, please wait..." });
    const unresolvedPromises = array.map(id => handleDelete(id));

    await Promise.all(unresolvedPromises).then(success => {
      setLoading({ status: true, data: "Succssfully deleted products!" });
    }).catch(error => {
      setLoading({ status: true, data: `Unable to delete product with id: ${error}` });
    });
    
    setFetchTrigger(fetchTrigger + 1);
    
    setTimeout(() => {
      setLoading({ status: false });
    }, 3000);
  };
  // -----------------------------------------------------------------------------------

  const handleApproveRequest = async (id) => {
    setLoading({ status: true, data: 'Checking product for approval request, please wait...' });
    
    const res = await ProductsApi.productRequest(id, {});

    if(res.success) {
      setLoading({ status: true, data: "Successfully requested product approval" });
      setTimeout(() => {
        setFetchTrigger(fetchTrigger + 1);
        setLoading({ status: false });
      }, 3000);
    } else {
      if(res.errors) {
        setLoading({ status: true, data: 'Validation Errors!, Please Wait....' });
        setTimeout(() => {
          history.push({
            pathname: `/products/edit/${id}`,
            state: { hasErrors: res.errors }
          });
          setLoading({ status: false });
        }, 3000);
      } else {
        setLoading({ status: true, data: res.message });
        setTimeout(() => {
            setLoading({ status: false });
        }, 3000);
      }
    }
  }

  const handleCancelRequest = async (id) => {
    let message_a = 'Cancelling approval request for product, please wait...';
    let message_b = 'Unable to cancel approval request for product!';

    setLoading({ status: true, data: message_a });
    const res = await ProductsApi.productCancellation(id, {});

    if (res.errors) {
      setLoading({ status: true, data: message_b });
      setTimeout(setLoading({ status: false }), 3000);
    } else {
      setLoading({ status: false });
    }

    setFetchTrigger(fetchTrigger + 1);
  }

  const columns = [
    { field: "ref", headerName: "Ref#", flex: 0.3 },
    { field: "version", headerName: "ver", flex: 0.1 },
    { field: "name", headerName: "Product Name", flex: 1 },
    {
      field: "sbu_id",
      headerName: "Business Unit",
      minWidth: 200,
      renderCell: (cellValues) => {
          if(cellValues.row.sbu_id) {
              const pc = bus.find(e => cellValues.row.sbu_id === e.id)
              return pc.name;
          } else {
              return null;
          }
      }
    },
    { field: "owner_name", headerName: "Product Owner", flex: 0.5 },
    {
      field: "",
      headerName: "Action",
      minWidth: 240,
      renderCell: (cellValues) => {
        return (
          <div>
            <ModuleButton
              btnIcon="visibility"
              style={{
                background: context.theme.sidebar.background,
                color: "white"
              }}
              onClick={(event) => handleLinkClick(event, cellValues.row, "view")}
              tip="View"
            />
            {edit && !cellValues.row.requested &&
              <ModuleButton
                btnIcon="edit"
                style={{
                  background: "orange",
                  color: "white",
                  marginLeft: 10
                }}
                onClick={(event) => handleLinkClick(event, { ...cellValues.row, hasErrors: false }, "edit")}
                tip="Edit"
              />
            }
            {edit && !cellValues.row.approved ? (
              cellValues.row.requested ? (
                <ModuleButton
                  btnIcon="backspace"
                  style={{
                    background: "red",
                    color: "white",
                    marginLeft: 10
                  }}
                  tip="Cancel Request"
                  onClick={() => handleCancelRequest(cellValues.row.id)}
                />
              ) : (
                <ModuleButton
                  btnIcon="playlist_add_check"
                  style={{
                    background: "green",
                    color: "white",
                    marginLeft: 10
                  }}
                  tip="Request Approval"
                  onClick={() => handleApproveRequest(cellValues.row.id)}
                />
              )
            ) : (
              <>
              </>
            )}
          </div>
        );
      },
    }
  ];

  const options = {
    page: page,
    handlePageChange: handlePageChange,
    pageSize: pageSize,
    handleSizeChange: handleSizeChange,
    rowsPerPageOptions: [10, 25, 100],
    rowCount: rowCount,
    autoHeight: true,
    height: 680
  };
  
  useEffect(() => {
    getProds();
  }, [fetchTrigger]);


  if (read && data) {
    return (
      <>
        <DataTable
          columns={columns}
          data={data}
          pagination={pagination}
          options={options}
          allowDelete={CanDelete("products")}
          handleDeleteArray={handleDeleteArray}
          searchText={searchText}
          setSearchText={setSearchText}
          fetchTrigger={fetchTrigger}
          setFetchTrigger={setFetchTrigger}
        />
        <ApiLoaderComponent status={loading.status} data={loading.data} />
      </>
    );
  } else {
    return <LoadingComponent color={context.theme.sidebar.background} />;
  }
}
