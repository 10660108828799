import React from "react";
import { BrowserRouter as Router, Switch, Route, useHistory } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import AuthContext from "./services/AuthContext";
import Products from "./pages/index";
import ProductView from "./pages/view";
import ProductEdit from "./pages/edit";
import ProductCreate from "./pages/create";

export default function Root(props) {
  let context = { ...props };

  return (
    <AuthContext.Provider value={{ ...props }}>
      <Router>
        <Route
          render={({ location }) => (
            <TransitionGroup className="transition-group">
              <CSSTransition key={location.key} timeout={300} classNames="fade">
                <section className="route-section">
                  <Switch location={location}>
                    <Route path="/location" exact component={Location} /> 

                    {props.auth.access.products["A"] &&
                      props.auth.access.products["R"] && (
                        <Route
                          path="/products/index"
                          exact
                          component={(route) => (
                            <Products
                              route={route}
                              context={context}
                            />
                          )}
                        />
                      )}

                    {props.auth.access.products["A"] &&
                      props.auth.access.products["R"] && (
                        <Route
                          path="/products/view/:id"
                          exact
                          component={(route) => (
                            <ProductView
                              route={route}
                              context={context}
                            />
                          )}
                        />
                      )}
                    {props.auth.access.products["A"] &&
                      props.auth.access.products["C"] && (
                        <Route
                          path="/products/create"
                          exact
                          component={(route) => (
                            <ProductCreate
                              route={route}
                              context={context}
                            />
                          )}
                        />
                      )}
                    {props.auth.access.products["A"] &&
                      props.auth.access.products["U"] && (
                        <Route
                          path="/products/edit/:id"
                          exact
                          component={(route) => (
                            <ProductEdit
                              route={route}
                              context={context}
                            />
                          )}
                        />
                      )}
                  </Switch>
                </section>
              </CSSTransition>
            </TransitionGroup>
          )}
        />
      </Router>
    </AuthContext.Provider>
  );
}
