import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AppHeaderComponent } from "@unity/components";
import { CanCreate, CanRead, CanReadAll, CanUpdate, CanModuleAdmin } from "../services/Permissions";
import ModuleFunctions from "../modules/ModuleFunctions";
import ModuleTabs from "../common/ModuleTabs";
import ModuleButton from "../common/ModuleButton";
import WorkingIndex from "../modules/WorkingIndex";
import ActiveIndex from "../modules/ActiveIndex";
import ArchivedIndex from "../modules/ArchivedIndex";
import ReviewIndex from "../modules/ReviewIndex";
import ProductStructure from "../modules/ProductStructure";
import Settings from "../modules/Settings";

export default function Products(props) {
  const [value, setValue] = useState(0);
  const [bus, setBus] = useState(false);

  const create = CanCreate("products");
  const read = CanRead("products");
  const edit = CanUpdate("products");
  const admin = CanModuleAdmin("products");
  const readAll = CanReadAll("products");

  const busChange = (data) => {
    setBus(data);
  };

  const handleChange = async (event, newValue) => {
    setValue(newValue);
  };

  const tabs = [
    { icon: "update", label: "draft" },
    { icon: "build", label: "active" },
    { icon: "archive", label: "archived" }
  ];

  if (admin) {
    tabs.push(
      { icon: "rate_review", label: "action list" },
      { icon: "widgets", label: "structure" },
      { icon: "settings", label: "module settings" }
    )
  };

  useEffect(() => {
    ModuleFunctions.getBusinesses({ change: busChange });
  }, []);
  

  return (
    <>
      {create && (value !== 4 && value !== 5) ? (
        <AppHeaderComponent
          addNew={() => <Link to={"/products/create"}>
            <ModuleButton
              text="Add Product"
              btnIcon="add"
              stylename="primary"
              style={
                {
                  background: props.context.theme.top_menu.button_background,
                  color: props.context.theme.top_menu.button_font,
                }
              }
            />
          </Link>}
          context={props.context}
          theme={props.context.theme}
          name={"products"}
          spacer={true}
        />
      ) : (
        <AppHeaderComponent
          context={props.context}
          theme={props.context.theme}
          name={"products"}
          spacer={true}
        />
      )}
      
      <ModuleTabs tabs={tabs} value={value} setValue={setValue} handleChange={handleChange} />
        {value === 0 && 
          <div style={{ paddingTop: 86 }}>
            <WorkingIndex
              context={props.context}
              create={create}
              read={read}
              edit={edit}
              admin={admin}
              readAll={readAll}
              bus={bus}
            />
          </div>}
        {value === 1 && 
          <div style={{ paddingTop: 86 }}>
            <ActiveIndex
              context={props.context}
              create={create}
              read={read}
              edit={edit}
              admin={admin}
              readAll={readAll}
              bus={bus}
            />
          </div>}
        {value === 2 && 
          <div style={{ paddingTop: 86 }}>
            <ArchivedIndex
              context={props.context}
              create={create}
              read={read}
              edit={edit}
              admin={admin}
              readAll={readAll}
              bus={bus}
            />
          </div>}
        {value === 3 && 
          <div style={{ paddingTop: 86 }}>
            <ReviewIndex
              context={props.context}
              create={create}
              read={read}
              edit={edit}
              admin={admin}
              readAll={readAll}
              bus={bus}
            />
          </div>}
        {value === 4 && 
          <div style={{ paddingTop: 146 }}>
            <ProductStructure
              context={props.context}
              create={create}
              read={read}
              edit={edit}
              admin={admin}
              readAll={readAll}
            />
          </div>}
        {value === 5 &&
          <div style={{ paddingTop: 146 }}>
            <Settings context={props.context} />
        </div>}
    </>
  );
}
