import React, { useEffect, useState } from "react";
import { FormControl, FormHelperText, InputLabel, Select, MenuItem, TextField, InputAdornment } from "@mui/material";
import ModuleFunctions from "./ModuleFunctions";
import OutputLines from "../modules/OutputLines";

export default function ProductOverview(props) {
    const [prodCats, setProdCats] = useState(false);
    const [groups, setGroups] = useState(false);
    const [lines, setLines] = useState(false);

    const uneditable = ((props.type === 'view') || !props[props.type] || props.data.retired);

    const prodCatsChange = (data) => {
        setProdCats(data);
    };

    const groupsChange = (data) => {
        setGroups(data);
    };

    const linesChange = (data) => {
        setLines(data);
    };


    useEffect(() => {
        if(props.data.sbu_id) {
            ModuleFunctions.setCurrentProductStructure({
                data: props.data,
                structure: props.structure,
                prodCatsChange: prodCatsChange,
                groupsChange: groupsChange,
                linesChange: linesChange
            });
        }
    }, []);



    return (
        <div className="block">
            <div className="container-fluid">
                <div style={{ width: '100%' }}>
                    <div className="form-row">
                        <div className="form-group col-lg-12">
                            <h4>Product Overview</h4>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group-sm col-lg-6">
                            <TextField
                                label="Public Name"
                                name="name"
                                type="text"
                                value={props.data.name || ""}
                                error={props.errors && props.errors.name || false}
                                helperText={props.errors && props.errors.name || ""}
                                onChange={(e) => uneditable || ModuleFunctions.handleProductChange({ name: e.target.name, value: e.target.value, productChange: props.productChange })}
                                size="small"
                                fullWidth
                                InputProps={{ readOnly: uneditable }}
                            ></TextField>
                        </div>

                        <div className="form-group-sm col-lg-2">
                            <TextField
                                label="Ref#"
                                name="ref"
                                type="text"
                                value={props.data.ref || ""}
                                size="small"
                                fullWidth
                                disabled
                            ></TextField>
                        </div>

                        <div className="form-group-sm col-lg-1">
                            <TextField
                                label="Version"
                                name="version"
                                type="text"
                                value={props.data.version || ""}
                                size="small"
                                fullWidth
                                disabled
                            ></TextField>
                        </div>

                        <div className="form-group-sm col-lg-3">
                            <TextField
                                label="Stage"
                                name="life_cycle_stage"
                                type="text"
                                value={props.data.life_cycle_stage || ""}
                                size="small"
                                fullWidth
                                disabled
                            ></TextField>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group-sm col-lg-4">
                            <FormControl error={props.errors && props.errors.owner_id || false} fullWidth>
                                <InputLabel>Product Owner</InputLabel>
                                <Select
                                    label="Product Owner"
                                    name="owner_id"
                                    value={props.data.owner_id || ""}
                                    onChange={(evt, personObj) => uneditable || ModuleFunctions.handleOwnerChange({
                                        event: evt,
                                        element: personObj,
                                        productChange: props.productChange
                                    })}
                                    inputProps={{ readOnly: uneditable }}
                                    size="small"
                                >
                                    {props.personnel && props.personnel.map((item, key) => {
                                        return <MenuItem key={key} value={item.contact_id} personObj={item}>{item.name}</MenuItem>
                                    })}
                                </Select>
                                <FormHelperText>{props.errors && props.errors.owner_id || ""}</FormHelperText>
                            </FormControl>
                        </div>

                        <div className="form-group-sm col-lg-4">
                            <FormControl error={props.errors && props.errors.sbu_id || false} fullWidth>
                                <InputLabel>Strategic Business Unit</InputLabel>
                                <Select
                                    label="Strategic Business Unit"
                                    name="sbu_id"
                                    value={props.data.sbu_id || ""}
                                    onChange={(evt, structObj) => uneditable || ModuleFunctions.handleSbuChange({
                                        current: props.data.sbu_id,
                                        selected: evt.target.value,
                                        element: structObj,
                                        productChange: props.productChange,
                                        prodCatsChange: prodCatsChange
                                    })}
                                    inputProps={{ readOnly: uneditable }}
                                    size="small"
                                >
                                    {props.structure && props.structure.map((item, key) => {
                                        return <MenuItem key={key} value={item.id} structObj={item}>{item.name}</MenuItem>
                                    })}
                                </Select>
                                <FormHelperText>{props.errors && props.errors.sbu_id || ""}</FormHelperText>
                            </FormControl>
                        </div>

                        <div className="form-group-sm col-lg-4">
                            <FormControl error={props.errors && props.errors.prod_cat_id || false} fullWidth>
                                <InputLabel>Product Category</InputLabel>
                                {prodCats ? (
                                    <Select
                                        label="Product Category"
                                        name="prod_cat_id"
                                        value={props.data.prod_cat_id || ""}
                                        onChange={(evt, structObj) => uneditable || ModuleFunctions.handleProdCatChange({
                                            current: props.data.prod_cat_id,
                                            selected: evt.target.value,
                                            element: structObj,
                                            productChange: props.productChange,
                                            groupsChange: groupsChange
                                        })}
                                        inputProps={{ readOnly: uneditable }}
                                        size="small"
                                    >
                                        {prodCats && prodCats.map((item, key) => {
                                            return <MenuItem key={key} value={item.id} structObj={item}>{item.name}</MenuItem>
                                        })}
                                    </Select>
                                    ) : (
                                    <Select
                                        value={0}
                                        name="prod_cat_id"
                                        disabled
                                        fullWidth
                                        size="small"
                                    >
                                        <MenuItem value={0}>Please choose a Strategic Business Unit</MenuItem>
                                    </Select>
                                )}
                                <FormHelperText>{props.errors && props.errors.prod_cat_id || ""}</FormHelperText>
                            </FormControl>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group-sm col-lg-4">
                            <FormControl error={props.errors && props.errors.group_id || false} fullWidth>
                                <InputLabel>Product Group</InputLabel>
                                {groups ? (
                                    <Select
                                        label="Product Group"
                                        name="group_id"
                                        value={props.data.group_id || ""}
                                        onChange={(evt, structObj) => uneditable || ModuleFunctions.handleProdGroupChange({
                                            current: props.data.group_id,
                                            selected: evt.target.value,
                                            element: structObj,
                                            productChange: props.productChange,
                                            linesChange: linesChange
                                        })}
                                        inputProps={{ readOnly: uneditable }}
                                        size="small"
                                    >
                                        {groups && groups.map((item, key) => {
                                            return <MenuItem key={key} value={item.id} structObj={item}>{item.name}</MenuItem>
                                        })}
                                    </Select>
                                    ) : (
                                    <Select
                                        value={0}
                                        name="group_id"
                                        disabled
                                        fullWidth
                                        size="small"
                                    >
                                        <MenuItem value={0}>Please choose a Product Category</MenuItem>
                                    </Select>
                                )}
                                <FormHelperText>{props.errors && props.errors.group_id || ""}</FormHelperText>
                            </FormControl>
                        </div>
                                
                        <div className="form-group-sm col-lg-4">
                            <FormControl error={props.errors && props.errors.line_id || false} fullWidth>
                                <InputLabel>Product Line</InputLabel>
                                {lines ? (
                                    <Select
                                        label="Product Line"
                                        name="line_id"
                                        value={props.data.line_id || ""}
                                        onChange={(e) => uneditable || ModuleFunctions.handleProductChange({ name: e.target.name, value: e.target.value, productChange: props.productChange })}
                                        inputProps={{ readOnly: uneditable }}
                                        size="small"
                                    >
                                        {lines && lines.map((item, key) => {
                                            return <MenuItem key={key} value={item.id}>{item.name}</MenuItem>
                                        })}
                                    </Select>
                                    ) : (
                                    <Select
                                        value={0}
                                        name="line_id"
                                        disabled
                                        fullWidth
                                        size="small"
                                    >
                                        <MenuItem value={0}>Please choose a Product Group</MenuItem>
                                    </Select>
                                )}
                                <FormHelperText>{props.errors && props.errors.line_id || ""}</FormHelperText>
                            </FormControl>
                        </div>
                                
                        <div className="form-group-sm col-lg-4">
                            <FormControl error={props.errors && props.errors.channel_id || false} fullWidth>
                                <InputLabel>Sales Channel</InputLabel>
                                <Select
                                    label="Sales Channel"
                                    name="channel_id"
                                    value={props.data.channel_id || ""}
                                    onChange={(e) => uneditable || ModuleFunctions.handleProductChange({ name: e.target.name, value: e.target.value, productChange: props.productChange })}
                                    inputProps={{ readOnly: uneditable }}
                                    size="small"
                                >
                                    {props.channels && props.channels.map((item, key) => {
                                        return <MenuItem key={key} value={item.id}>{item.name}</MenuItem>
                                    })}
                                </Select>
                                <FormHelperText>{props.errors && props.errors.channel_id || ""}</FormHelperText>
                            </FormControl>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group-sm col-lg-12">
                            <TextField
                                label="Public Description"
                                name="description"
                                type="text"
                                value={props.data.description || ""}
                                multiline
                                minRows={3}
                                maxRows={10}
                                error={props.description && props.errors.description || false}
                                helperText={props.description && props.errors.description || ""}
                                onChange={(e) => uneditable || ModuleFunctions.handleProductChange({ name: e.target.name, value: e.target.value, productChange: props.productChange })}
                                size="small"
                                fullWidth
                                InputProps={{ readOnly: uneditable }}
                            ></TextField>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group-sm col-lg-12">
                            <TextField
                                label="Internal Notes"
                                name="notes"
                                type="text"
                                value={props.data.notes || ""}
                                multiline
                                minRows={3}
                                maxRows={10}
                                error={props.errors && props.errors.notes || false}
                                helperText={props.errors && props.errors.notes || ""}
                                onChange={(e) => uneditable || ModuleFunctions.handleProductChange({ name: e.target.name, value: e.target.value, productChange: props.productChange })}
                                size="small"
                                fullWidth
                                InputProps={{ readOnly: uneditable }}
                            ></TextField>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group-sm col-lg-12">
                            <OutputLines
                                uneditable={uneditable}
                                output_lines={props.data.output_lines || []}
                                periods={props.periods}
                                charges={props.charges}
                                methods={props.methods}
                                vats={props.vats}
                                componentChange={props.componentChange}
                                productChange={props.productChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
