import React, { useState } from "react";
import { Dialog, DialogTitle, Snackbar, Button, CircularProgress, Icon } from "@mui/material";
import ModuleButton from "../common/ModuleButton";
import DataTable from "../common/DataTable";
import { ProductsApi } from "@unity/components";
import ModuleFunctions from "./ModuleFunctions";

export default function ProductDocuments(props) {
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackMessge, setSnackMessage] = useState("");
    const [uploadData, setUploadData] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [searchText, setSearchText] = useState('');

    const data = props.data;

    const resetMedia = () => {
        setSnackMessage("");
        setSnackOpen(false);
        setUploading(false);
        setUploadData(false);
        props.onClose(false);
    };

    const handleInputChange = (event) => {
        const files = event.target.files;
        let toUpload = [];

        for (let i = 0; i < files.length; i++) {
            let reader = new FileReader();
            reader.readAsDataURL(files[i]);
            reader.onload = (e) => {
                toUpload.push({
                    base64: e.target.result,
                    name: files[i].name,
                    type: files[i].type,
                    size: files[i].size
                });
            }
        };

        setUploadData( toUpload );
    };

    const handleLink = async (id) => {
        const newLinks = {
            object_id: props.object_id,
            object_type: props.object_type,
            details: [id]// if you change so multiple items can be linked then just add them to this array 
        };

        const res = await ProductsApi.addDocuments(newLinks);

        if(res.success) {
            setSnackMessage("Successful");
            setSnackOpen(true);
        } else {
            setSnackMessage("Linking Failed");
            setSnackOpen(true);
        }

        props.triggerChange();
    };

    const handleStore = async () => {
        setUploading(true);

        if (typeof uploadData === "undefined") {
            setSnackMessage("Please Select a File First");
            setSnackOpen(true);
            setUploading(false);
            setUploadData(false);
        } else {
            const newDocs = {
                object_id: props.object_id,
                object_type: props.object_type,
                tag_id: null,
                // admin_only: false,
                resources: uploadData
            };

            const res = await ProductsApi.saveDocument(newDocs);

            if(res.success) {
                setSnackMessage("Successful");
                setSnackOpen(true);
            } else {
                setSnackMessage("Upload Failed");
                setSnackOpen(true);
            }

            props.triggerChange();
        }
    };

    const columns = [
        { field: "name", headerName: "File Name", flex: 0.5 },
        { field: "size", headerName: "File Size", flex: 0.1 },
        { field: "type", headerName: "File Type", flex: 0.2 },
        { field: "tag_name", headerName: "Tagged As", flex: 0.2 },
        {
            field: "",
            headerName: "Actions",
            minWidth: 350,
            renderCell: (cellValues) => {
                return (
                    cellValues.row.admin_only && !props.admin ? (
                        null
                    ) : (
                        <div>
                            {typeof props.linkedDocs.find(e => e.id == cellValues.row.id) === "undefined" ? (
                                <ModuleButton
                                    text="link"
                                    btnIcon="link"
                                    style={{
                                      background: "green",
                                      color: "white",
                                      marginRight: 10
                                    }}
                                    onClick={() => handleLink(cellValues.row.id)}
                                />
                            ) : (
                                <ModuleButton
                                    text="unlink"
                                    btnIcon="link_off"
                                    style={{
                                      background: "red",
                                      color: "white",
                                      marginRight: 10
                                    }}
                                    onClick={() => ModuleFunctions.handleUnlink({
                                        object_id: props.object_id,
                                        object_type: props.object_type,
                                        id: cellValues.row.id,
                                        triggerChange: props.triggerChange
                                    })}
                                />
                            )}
                            <ModuleButton
                                text="view"
                                btnIcon="visibility"
                                style={{
                                  background: props.context.theme.sidebar.background,
                                  color: "white",
                                  marginRight: 10
                                }}
                                onClick={() => ModuleFunctions.handleView({ id: cellValues.row.vault_id })}
                            />
                            <ModuleButton
                                text="edit"
                                btnIcon="edit"
                                style={{
                                  background: "orange",
                                  color: "white"
                                }}
                                onClick={() => props.documentChange({
                                    id: cellValues.row.id,
                                    name: cellValues.row.name,
                                    description: cellValues.row.description,
                                    tag_id: cellValues.row.tag_id,
                                    visibility_id: cellValues.row.visibility_id
                                })}
                            />
                        </div>
                    )
                );
            }
        }
    ];

    const options = {
        page: 0,
        autoHeight: true,
        height: 680
    };

    return (
        <div id="media-library">
            
            <Dialog
                fullWidth
                maxWidth="xl"
                open={props.open}
                onClose={() => props.onClose(false)}
                scroll={"paper"}
                style={{ padding: "15px" }}
            >
                <DialogTitle
                    style={{
                        background: props.context.theme.sidebar.background,
                        color: "white",
                    }}
                >
                    <ModuleButton
                        btnIcon="close"
                        style={{
                          background: "red",
                          color: "white",
                          marginRight: 25
                        }}
                        tip="Close product documents library"
                        onClick={() => resetMedia()}
                    />
                    Media Library
                </DialogTitle>

                <div style={{ padding: "15px" }}>
                    <div>
                        <div className="form-row">
                            <div className="form-group col-lg-12">
                                <Button
                                    variant="contained"
                                    component="label"
                                    onChange={(evt) => handleInputChange(evt)}
                                    style={{
                                        background: props.context.theme.sidebar.background,
                                        color: "white",
                                        marginRight: 25
                                      }}
                                >
                                    add new files
                                    <input type="file" hidden={true} multiple />
                                </Button>
                                {uploadData && (
                                    <Button
                                        disabled={uploading}
                                        variant="contained"
                                        onClick={() => handleStore()}
                                        style={{
                                            background: "green",
                                            color: "white",
                                            marginRight: 25
                                        }}
                                    >
                                        {uploading ? (
                                            <CircularProgress color="success" size={24} />
                                        ) : (
                                            <Icon>upload</Icon>
                                        )}
                                        Upload
                                    </Button>
                                )}
                                <i>Max combined file size: 100MB</i>
                            </div>
                        </div>
                    </div>

                    <DataTable
                        columns={columns}
                        data={data}
                        pagination={false}
                        options={options}
                        allowDelete={false}
                        searchText={searchText}
                        setSearchText={setSearchText}
                    />

                    <Snackbar
                        open={snackOpen}
                        message={snackMessge}
                        onClose={() => {
                            setSnackOpen(false);
                            setSnackMessage("");
                        }}
                        autoHideDuration={5000}
                    />
                </div>
            </Dialog>
        </div>
    );
}
